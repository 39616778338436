<template>
  <div id="account" class="mb-5">
    <div class="columns is-multiline">
      <div
        class="column is-8 is-offset-2 card mb-3"
        v-for="item in items"
        :key="item.name"
      >
        <router-link :to="item.route">{{ item.name }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageTitle: 'Account',
      schoolId: null,
    }
  },
  computed: {
    items() {
      return [
        {
          name: 'Change Email',
          route: `/school/${this.schoolId}/fee_templates`,
        },
        {
          name: 'Change Password',
          route: `/school/${this.schoolId}/fee_items`,
        },
        {
          name: 'Two Factor Authentication',
          route: `/school/${this.schoolId}/school_fees`,
        },
      ]
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [])
  },
}
</script>
